import React from 'react';
import { Card } from 'react-bootstrap';

// Define the structure of the postTrends data
interface TrendItem {
  winner: 'red' | 'blue' | 'draw' | 'cancelled';
  matchNo: number;
}

interface PostTrends {
  data: TrendItem[];
}

const Trends = ({ postTrends, loadingStatusTrends }: { postTrends: PostTrends, loadingStatusTrends: boolean }) => {

  const renderTrendItem = (val: 'red' | 'blue' | 'draw' | 'cancelled', gameNumber: number) => {
    let className = '';
    switch (val) {
      case 'blue':
        className = 'trend-wala';
        break;
      case 'red':
        className = 'trend-meron';
        break;
      case 'draw':
        className = 'trend-draw';
        break;
      case 'cancelled':
        className = 'trend-cancel';
        break;
      default:
        className = '';
        break;
    }

    if (className !== '')
        return <td key={gameNumber}><div className={`trend-item ${className}`}>{gameNumber}</div></td>;
    else
        return <td key={gameNumber}><div className="trend-item trend-empty"></div></td>;
  };

  const generateTableRows = () => {
  if (loadingStatusTrends || postTrends.data.length === 0) return [];

  const tableRows: JSX.Element[][] = []; // This will contain our rows of columns
  let currentColumn: JSX.Element[] = []; // This holds the current column's data
  let prevResult: 'red' | 'blue' | 'draw' | 'cancelled' | null = null;
  let trCount = 0;
  let gameNumber = 0;

  postTrends.data.forEach((item, i) => {
    const { winner: val, matchNo } = item;
    gameNumber = matchNo;

    if (i === 0) {
      // Initialize prevResult for the first row
      prevResult = val;
      currentColumn.push(renderTrendItem(val, gameNumber)); // Start the first column with the first result
    } else {
      if (val === prevResult && trCount < 6 && val !== 'cancelled') {
        // Continue adding to the same column if result is the same (but not if it�s cancelled)
        currentColumn.push(renderTrendItem(val, gameNumber));
        trCount++;
      } else {
        // Add the current column to tableRows and start a new column
        if (currentColumn.length > 0) {
          tableRows.push(currentColumn);
        }
        // Start a new column
        currentColumn = [renderTrendItem(val, gameNumber)];
        trCount = 1; // Reset the row count for the new column
      }
    }

    // Update prevResult for the next iteration
    prevResult = val;
  });

  // Push the last column if it has data
  if (currentColumn.length > 0) {
    tableRows.push(currentColumn);
  }

  // Generate rows with the data in the columns
  const maxRows = 6;
  const resultRows: JSX.Element[] = [];

  // Ensure all columns have 6 rows, fill with empty cells if necessary
  for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
    const row: JSX.Element[] = [];
    tableRows.forEach((column, columnIndex) => {
      row.push(column[rowIndex] || <td key={`empty-${rowIndex}-${columnIndex}`}><div className="trend-item trend-empty"></div></td>);
    });
    resultRows.push(<tr key={rowIndex}>{row}</tr>);
  }

  return resultRows;
};


  return (
    <Card className="play-card mb-2">
      <Card.Body className=''>
        <div className="mb-3">
          <div className="play_resultLabel">
            <div className="meron">
              <div className="circle meron"><span id="lblResultMeron"></span></div>
              RED
            </div>
            <div className="wala">
              <div className="circle wala"><span id="lblResultWala"></span></div>
              BLUE
            </div>
            <div className="draw">
              <div className="circle draw"><span id="lblResultDraw"></span></div>
              DRAW
            </div>
            <div className="meron">
              <div className="circle cancel"><span id="lblResultCancel"></span></div>
              CANCEL
            </div>
          </div>
        </div>
        <div className="gp-panel-bg rounded-bottom landscape-trend">
          <div className="table-responsive pt-2 pb-2 gp-scroll trend-table-wrapper">
            <table className="trend-table">
              <tbody>
                {generateTableRows()}
              </tbody>
            </table>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Trends;