import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { clientAxios} from 'utility/api/operator/axiosOperator';
import { API } from '../../resources/api-constants';
import { formatDate } from 'utility/functions';
import { ROUTES } from 'resources/routes-constants';
import { pusherInit } from '../../utility/pusher';

const useOperatorMatchLogic = () => {

    const [loadingStatus, setLoadingStatus] = useState(true);
    //get details
    const idTemp = useParams();
    const [categoryId, setCategoryId] = useState(idTemp.categoryid);
    const [gameID, setGameID] = useState(idTemp.gameid);
    const [id, setId] = useState(idTemp.matchid);
    const [fightNumber, setFightNumber] = useState(idTemp.fightNumber);

    const queryParams = new URLSearchParams(window.location.search);
    const [post, setPost] = useState({ data: Array(2) });
    const [previousMatch, setPreviousMatch] = useState();
    const [nextMatch, setNextMatch] = useState();
    const [error, setError] = useState('');

    const [matchName, setMatchName] = useState('');
    const [matchDate, setMatchDate] = useState('');
    const [currentMatchDate, setCurrentMatchDate] = useState(queryParams.get('date'));

    const [betStatus, setBetStatus] = useState(0);
    const [matchResult, setMatchResult] = useState('');
    const [settleStatus, setSettleStatus] = useState('');
    const [status, setStatus] = useState('');
    const [stream, setStream] = useState('');
    const [team1, setTeam1] = useState('');
    const [team2, setTeam2] = useState('');

    const [pusherEventKey, setPusherEventKey] = useState('');

    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');

    const getPostData = async (id: any) => {
        try {
            const response = await clientAxios.get(
                API.OPERATOR_GAMES_MATCH_VIEW_LINK + '/' + gameID + '/match', {
                //API.OPERATOR_GAMES_MATCH_VIEW_LINK + '/1/1/matches/1/fights/1' , {
                params: {
                    //date: formatDate(matchDate),
                    date: currentMatchDate,
                    match: fightNumber,
                    //gameID: gameID,
                    //fightID: fightNumber
                }
            })
            setError('');
            setPost(response.data);
            setPreviousMatch(response.data.data.prevMatch);
            setNextMatch(response.data.data.nextMatch);

            setGameID(response.data.data.match.gameId);
            setMatchName(response.data.data.match.name);
            setMatchDate(formatDate(response.data.data.match.date));
            setFightNumber(response.data.data.match.matchNo);
            setBetStatus(response.data.data.match.betStatus);
            setMatchResult(response.data.data.match.winner);
            setSettleStatus(response.data.data.match.settleStatus);
            setStream(response.data.data.match.streamIFrame);
            setTeam1(response.data.data.match.teamOneName);
            setTeam2(response.data.data.match.teamTwoName);
            setStatus(response.data.data.match.status);

            setPusherEventKey(response.data.data.match.pusherEventKey);

            //setCreatedBy(response.data.data[0].createdBy);
            setCreatedAt(formatDate(response.data.data.match.createdAt));
            setUpdatedAt(formatDate(response.data.data.match.updatedAt));
        } catch (error) {
            setError('An error has occured. Please try again.');
        }

        setLoadingStatus(false);

    };

    const [pusherChannel, setPusherChannel] = useState(Object);

    React.useEffect(() => {
        getPostData(id);

        if ('2' === categoryId) {
            const pusher = pusherInit();

            const channel = pusher.subscribe('crypto-channel');
            setPusherChannel(channel);

            return (() => {
                pusher.unsubscribe('crypto-channel');
            })
        }


    }, []);
    //end get details

    //const handle = (redPercentage: any) => async (e: any) => {

    //};

    //UPDATE
    const [show, setShow] = useState(false);
    const [showBet, setShowBet] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseBet = () => setShowBet(false);
    const handleShowBet = () => setShowBet(true);

    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');
    //const result = true;

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const matchName = e.target.matchName.value;
        const matchDate = e.target.matchDate.value;
        const stream = e.target.stream.value;
        const teamName1 = e.target.teamName1.value;
        const teamName2 = e.target.teamName2.value;

        let result = true;


        //api call
        try {
            await clientAxios.patch(
                API.OPERATOR_GAMES_MATCH_UPDATE_LINK + '/' + gameID + '/update-match?date=' + matchDate + '&match=' + fightNumber, {
                //params: {
                //    date: matchDate,
                //    match: fightNumber
                //},
                name: matchName,
                streamIFrame: stream,
                teamOneName: teamName1,
                teamTwoName: teamName2
            })

            setError('');
        } catch (error) {
            result = false;
            setError('An error has occured. Please try again.');
        }

        getPostData(id);

        if (result) {
            setShowToastType('success');
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    };
    //END UPDATE

    //UPDATE BET CONFIRMATION
    const [showResultConfirmation, setShowResultConfirmation] = useState(false);
    const [confirmationType, setConfirmationType] = useState('');
    const [betStatusLabel, setBetStatusLabel] = useState('');
    const [betStatusUpdate, setBetStatusUpdate] = useState('');

    const handleResultCloseConfirmation = () => setShowResultConfirmation(false);

    const handleOpenConfirmation = (type: any, propId: any, betStatusUpdate: any) => {
        setShowResultConfirmation(true);

        setConfirmationType(type);
        setBetStatusUpdate(betStatusUpdate);

        if ('match-result' === type) {
            if ('blue' === betStatusUpdate) {
                setBetStatusLabel(team1);
            } else if ('red' === betStatusUpdate) {
                setBetStatusLabel(team2);
            } else if ('draw' === betStatusUpdate) {
                setBetStatusLabel('Draw');
            } else {
                setBetStatusLabel('Cancelled');
            }
        } else if ('bet-status' === type) {
            if (1 === betStatusUpdate) {
                setBetStatusLabel('Open');
            } else if (3 === betStatusUpdate) {
                setBetStatusLabel('Last Call');
            } else {
                setBetStatusLabel('Close');
            }
        } else if ('settle-match' === type) {
            setBetStatusLabel(betStatusUpdate);
        }
    }
    //END UPDATE BET CONFIRMATION

    //UPDATE STATUS
    const handleBetSubmit = (status: any, type: any) => async (e: any) => {
        e.preventDefault();

        let result = true;

        try {
            if ('bet-status' === type) {
                await clientAxios.patch(
                    API.OPERATOR_GAMES_MATCH_BET_UPDATE_LINK + '/' + gameID + '/match?date=' + matchDate + '&match=' + fightNumber, {
                    //id: id,
                    betStatus: status,
                    //gameID: gameID
                });
            } else if ('match-result' === type) {
                //check final bets first
                let queryType = "sabong";

                if (categoryId === '1') {
                    queryType = 'esports';
                } else if (categoryId === '3') {
                    queryType = 'sports';
                }

                await clientAxios.get(
                    API.OPERATOR_GAMES_MATCH_VIEW_BETS_LINK + '/' + queryType + '/' + categoryId + '/matches/' + id + '/view-total-bets',
                    {
                    }
                ).then(async (response) => {
                    //setBetTeam1(response.data.data.blueTotalBets);
                    //setBetTeam2(response.data.data.redTotalBets);
                    //setBetPercentTeam1(response.data.data.bluePercentage);
                    //setBetPercentTeam2(response.data.data.redPercentage);


                    //calculateBets(response.data.data.team_1, response.data.data.team_2);
                    //const response = await clientAxios.patch(
                    //    API.OPERATOR_GAMES_MATCH_RESULT_LINK + '/' + gameID + '/set-result?date=' + matchDate + '&match=' + fightNumber, {
                    //    winner: status
                    //});

                    if (response.data.data.bluePercentage <= 105 || response.data.data.redPercentage <= 105) {
                        await clientAxios.patch(
                            API.OPERATOR_GAMES_MATCH_RESULT_LINK + '/' + gameID + '/set-result?date=' + matchDate + '&match=' + fightNumber, {
                            winner: 'cancelled'
                        });
                    } else {
                        await clientAxios.patch(
                            API.OPERATOR_GAMES_MATCH_RESULT_LINK + '/' + gameID + '/set-result?date=' + matchDate + '&match=' + fightNumber, {
                            winner: status
                        });
                    }

                }).catch((error) => {

                });

            } else if ('settle-match' === type) {
                await clientAxios.patch(
                    API.OPERATOR_GAMES_MATCH_CURRENT_SETTLE_LINK + '/' + id + '/settle-match', {
                    matchResult: matchResult
                });
            } else {
                await clientAxios.patch(
                    API.OPERATOR_GAMES_MATCH_RESULT_LINK + '/' + gameID + '/set-result?date=' + matchDate + '&match=' + fightNumber, {
                    winner: null
                });

            }

            setError('');
        } catch (error) {
            // window.location.href=window.location.pathname;
            setError('An error has occured. Please try again.');
        }
        setShowResultConfirmation(false);

        //if ('bet-status' == type && '0' == status) { //to refresh token
        //    window.location.href = window.location.pathname;
        //}
        getPostData(id);
    }
    //END UPDATE STATUS

    //NEXT NEXT/PREV MATCH
    const handleNextMatch = (type: any, matchObj: any) => async (e: any) => {
        e.preventDefault();

        if (type === 'next') { //next match
            //check if next match already exists
            if (matchObj !== null) {
                //getPostData(matchObj.id);
                //window.history.replaceState(null, '', ROUTES.OPERATOR_MATCH_VIEW_ROUTE + '/' + categoryId + '/' + gameID + '/' + matchObj.id + '/' + matchObj.matchNo + '?date=' + matchDate);
                window.location.href = ROUTES.OPERATOR_MATCH_VIEW_ROUTE + '/' + categoryId + '/' + gameID + '/' + matchObj.id + '/' + matchObj.matchNo + '?date=' + formatDate(matchObj.date);

            } else { // create next match
                handleCreateNextMatch(type);

            }
        } else { // previous match
            //getPostData(matchObj.id);

            //window.history.replaceState(null, '', ROUTES.OPERATOR_MATCH_VIEW_ROUTE + '/' + categoryId + '/' + gameID + '/' + matchObj.id + '/' + matchObj.matchNo + '?date=' + matchDate);
            window.location.href = ROUTES.OPERATOR_MATCH_VIEW_ROUTE + '/' + categoryId + '/' + gameID + '/' + matchObj.id + '/' + matchObj.matchNo + '?date=' + formatDate(matchObj.date);
        }

    }
    //END NEXT NEXT/PREV MATCH

    //CREATE NEW MATCH FUNCTION
    function handleCreateNextMatch(type: any) {

        clientAxios.post(
            API.OPERATOR_GAMES_MATCH_CREATE_LINK + '/' + gameID + '/next-match',
            {
                date: currentMatchDate
            }
        ).then((response: any) => {

            //no we try reloading next match
            setMatchDate(formatDate(response.data.data.data.date));

            //console.log(ROUTES.OPERATOR_MATCH_VIEW_ROUTE + '/' + categoryId + '/' + gameID + '/' + response.data.data.data.id + '/' + response.data.data.data.matchNo + '?date=' + matchDate)

            window.location.href = ROUTES.OPERATOR_MATCH_VIEW_ROUTE + '/' + categoryId + '/' + gameID + '/' + response.data.data.data.id + '/' + response.data.data.data.matchNo + '?date=' + matchDate;

            setError('');
        }).catch((error: any) => {
        });

        //}).finally(() => {

        //});
    }
    //END CREATE NEW MATCH FUNCTION


    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleShow,
        showBet, handleCloseBet, handleShowBet,
        handleSubmit, handleNextMatch,
        error,
        id, categoryId, gameID, matchName, fightNumber, betStatus, matchResult, settleStatus,
        status, stream, team1, team2, matchDate,
        createdBy, createdAt, updatedAt,
        pusherEventKey,
        handleBetSubmit, betStatusLabel, betStatusUpdate,
        previousMatch, nextMatch,
        handleOpenConfirmation, showResultConfirmation, handleResultCloseConfirmation, confirmationType,
        pusherChannel,
        loadingStatus
    };
}

export default useOperatorMatchLogic;