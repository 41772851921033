import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';

import { clientAxios, refreshToken } from 'utility/api/members/axiosPlayer';
import { API } from '../../resources/api-constants';

const useGameLogic = () => {
    // refreshToken();
    
    //GET GAMES
    const [post, setPost] = useState({ data: Array(2) });
    const [error, setError] = useState(false);

    const [currentPage, setCurrentPage] = useState(useParams());
    const [loadingStatus, setLoadingStatus] = useState(true);

    const homeRedirect = useNavigate();

    async function getPostData() {
        await clientAxios.get(
            API.PLAYER_GAMES_LINK + '/' + currentPage.id + '/games',
            {
            }
        ).then((response) => {
            setPost(response.data);
            setError(false);

        }).catch((error) => {
            setError(true);
        });
        setLoadingStatus(false);
    }

    //fetch on initial load
    React.useEffect(() => {
        sessionStorage.setItem('current-category', String(currentPage.id)); //get current category

        getPostData();
    }, []);
    //END GET GAMES


    //PLAY REDIRECT
    //set gameid
    const handlePlayRedirect = (gameId: any) => (e: any) => {
        e.preventDefault();
        // const gameId = String(currentPage.id);
        sessionStorage.setItem('current-game', gameId);

        homeRedirect(ROUTES.PLAY_ROUTE);
    }

    //END PLAY REDIRECT

    return {
        post, handlePlayRedirect,
        loadingStatus
    };
}

export default useGameLogic;