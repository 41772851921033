import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Container from "react-bootstrap/Container";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { Chart, registerables } from "chart.js";
import { Chart as ReactChart } from 'react-chartjs-2'; // Import ReactChart from react-chartjs-2
import { CandlestickController, CandlestickElement } from "chartjs-chart-financial";
import 'chartjs-adapter-date-fns'; // Import the date adapter
import annotationPlugin from 'chartjs-plugin-annotation'; //Adding line Annotations
import { formatNumberToCurrency } from 'utility/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import LZString from 'lz-string';

// Register Chart.js components
Chart.register(...registerables, CandlestickController, CandlestickElement, annotationPlugin);

interface CryptoProps {
    pusherChannel2: {
        bind: (event: string, callback: (data: any) => void) => void;
        unbind: (event: string, callback: (data: any) => void) => void;
    };
}

interface Status {
    st: string;
    tl: number;
}

interface KlineData {
    t: string;
    o: number;
    h: number;
    l: number;
    c: number;
}

const Crypto: React.FC<CryptoProps> = ({ pusherChannel2 }) => {
    const [series, setSeries] = useState<{ name: string; data: any[] }[]>([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [status, setStatus] = useState<Status>({ st: "", tl: 0 });
    const [timeLeft, setTimeLeft] = useState(0);
    const [latestPrice, setLatestPrice] = useState(0);
    const [previousLastPrice, setPreviousLastPrice] = useState(0);
    const [percentChange, setPercentChange] = useState(0);

    useEffect(() => {
        const socket = io("wss://capi.billibet88sports.live");

        const handleSocketUpdate = (compressedData: string) => {
            try {
                // Decompress the received data
                const decompressedData = JSON.parse(LZString.decompressFromUTF16(compressedData));

                // Extract data
                setStatus(decompressedData.s);
                setTimeLeft(decompressedData.s.tl);
                setSeries([
                    {
                        name: "BTC/USDT",
                        data: decompressedData.k.map((item: any) => ({
                            x: item.t,
                            o: item.o,
                            h: item.h,
                            l: item.l,
                            c: item.c,
                        })),
                    },
                ]);
                setLatestPrice(decompressedData.lastPrice);
                setPreviousLastPrice(decompressedData.previousLastPrice);
                setDataLoaded(true);
                
                var percentageChange = ((decompressedData.k[19].c - decompressedData.lastPrice) / decompressedData.lastPrice) * 100;
                setPercentChange(percentageChange);
            } catch (error) {
                console.error("Error decompressing or processing data:", error);
            }
        };

        socket.on("crypto-data-update", handleSocketUpdate);

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (timeLeft <= 0) return;
        const timerId = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
        return () => clearInterval(timerId);
    }, [timeLeft]);

    // Calculate dynamic min and max for y axis
    const getMinMax = (klineData: KlineData[]) => {
        let minPrice = Math.min(...klineData.map((item) => item.l));
        let maxPrice = Math.max(...klineData.map((item) => item.h));

        // Add some padding
        //minPrice = minPrice * 0.98;
        //maxPrice = maxPrice * 1.02;

        return { minPrice, maxPrice };
    };

    const { minPrice, maxPrice } = dataLoaded ? getMinMax(series[0]?.data || []) : { minPrice: 0, maxPrice: 0 };

    return (
        <>
            <div className="c-play-poolbets text-center">
                <div className="c-play-poolbets__header">
                    <div className="left">
                        <img src="../logo.gif" className="w-50" alt="Logo" />
                        <span className="btn btn--high">HIGH</span>
                        <span className="btn btn--low">LOW</span>
                    </div>
                    <div className="right">
                        <FontAwesomeIcon icon={faCirclePlay} /> LIVE
                    </div>
                </div>

                {dataLoaded ? (
                    <>

                        <div>
                            <div className="c-play-poolbets__subheader">
                                <div className="control">
                                    {["OPEN", "CLOSE", "WIN"].map((state) => (
                                        <span
                                            key={state === "PENDING" ? "WIN" : state}
                                            className={`btn btn--${state.toLowerCase()} ${(status.st === state ||
                                                (status.st === "LAST CALL" && state === "OPEN") ||
                                                (status.st === "PENDING" && state === "WIN"))
                                                ? "btn--is-active" : ""
                                                }`}
                                        >
                                            {state}
                                        </span>
                                    ))}
                                </div>
                                {(status.st === 'CLOSE') && <div className="timer-clock">
                                    00:{timeLeft.toString().padStart(2, "0")}
                                </div>
                                }
                            </div>

                        </div>
                        {/* CHART */}
                        <div className="chart-container px-4">
                            {/* Candlestick */}
                            {(status.st === 'CLOSE') ? // CLOSED
                                <div>
                                    <div className="text-lg-center pb-2 d-flex align-items-center justify-content-between d-lg-block">
                                        <h2>{formatNumberToCurrency(series[0]?.data[19].c)}</h2>
                                        <div>= {formatNumberToCurrency(latestPrice)}&nbsp;
                                            {(percentChange < 0) ?
                                                <span className="text-red">
                                                    {(Math.ceil(Number(percentChange) * 100) / 100).toFixed(2)}%
                                                </span>
                                                : (percentChange > 0) ?
                                                    <span className="text-green">
                                                        +{(Math.ceil(Number(percentChange) * 100) / 100).toFixed(2)}%
                                                    </span>
                                                    :
                                                    <span className="text-">
                                                        0%
                                                    </span>
                                            }

                                        </div>
                                    </div>
                                    <div className="text-start chart-time">
                                        Time 1m
                                    </div>
                                    <div className="reactchart-container">
                                        <ReactChart
                                            className="margin-auto"
                                            type="candlestick"
                                            data={{
                                                datasets: [
                                                    {
                                                        label: "BTC/USDT",
                                                        data: series[0]?.data || [],
                                                        borderColors: {
                                                            up: "#228c22",
                                                            down: "#ff0e1f"
                                                        },
                                                        borderWidth: 1,
                                                        backgroundColors: {
                                                            up: "#228c22",
                                                            down: "#ff0e1f"
                                                        }
                                                    },
                                                ],
                                            }}
                                            options={{
                                                maintainAspectRatio: true,
                                                responsive: true,
                                                scales: {
                                                    x: {
                                                        type: "time",
                                                        time: {
                                                            unit: "minute",
                                                        },
                                                        title: {
                                                            display: false,
                                                            text: "Time",
                                                        },
                                                    },
                                                    y: {
                                                        title: {
                                                            display: false,
                                                            text: "Price",
                                                        },
                                                        stacked: true,
                                                        min: minPrice, // Ensure dynamic min is applied
                                                        max: maxPrice, // Ensure dynamic max is applied
                                                        position: "right",
                                                        beginAtZero: false,
                                                        /*ticks: {
                                                            callback: function(value) {
                                                                const priceMargin = 0.00001;
                                                                if ((Number(value) - latestPrice) < priceMargin) {
                                                                    return `${latestPrice}`;
                                                                }
                                                                return value;
                                                            },
                                                            font: {
                                                                size: 10,
                                                                weight: 'bold',
                                                            }
                                                        }*/
                                                    },
                                                },
                                                layout: {
                                                    padding: {
                                                        bottom: 20
                                                    }
                                                },
                                                plugins: {
                                                    tooltip: {
                                                        enabled: false
                                                    },
                                                    legend: {
                                                        display: false,
                                                        labels: {
                                                            usePointStyle: false, // Disable color indicators (color box)
                                                        },
                                                    },
                                                    annotation: {
                                                        annotations: {
                                                            currentPriceLine: {
                                                                type: "line",
                                                                yMin: latestPrice,
                                                                yMax: latestPrice,
                                                                borderColor: "gray",
                                                                borderWidth: 1,
                                                                borderDash: [5, 5],
                                                                label: {
                                                                    content: `${latestPrice}`,
                                                                    position: "end",
                                                                    backgroundColor: "rgba(255,99,132,0.8)",
                                                                    color: "gray",
                                                                },
                                                            },
                                                        },
                                                    },
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                : (status.st === 'OPEN' || status.st === 'LAST CALL') ? //OPEN && LAST CALL
                                    <div className="open-bet">
                                        <div className="text-center h2 pb-2 pb-lg-4">
                                            BTC/USDT <span className="text-warning blinkText d-block">{formatNumberToCurrency(series[0]?.data[19].c)}</span>
                                        </div>
                                        <div className="text-center place-bets h3 pt-2 pb-2 pb-lg-3 pt-lg-4">
                                            <span>P</span>
                                            <span>L</span>
                                            <span>A</span>
                                            <span>C</span>
                                            <span>E</span>
                                            <span>&nbsp;</span>
                                            <span>Y</span>
                                            <span>O</span>
                                            <span>U</span>
                                            <span>R</span>
                                            <span>&nbsp;</span>
                                            <span>B</span>
                                            <span>E</span>
                                            <span>T</span>
                                            <span>S</span>
                                        </div>
                                        <div className="text-warning blinkText last-call-text">{(status.st === 'LAST CALL' && 'LAST CALL')}</div>
                                        <div className="timer text-center pb-3">
                                            <div className="timer-clock">
                                                00:{timeLeft.toString().padStart(2, "0")}
                                            </div>
                                        </div>
                                    </div>
                                    : //PENDING
                                    <div className="pending-bet text-center">
                                        <div className="pb-2 pb-lg-4">
                                            <h2 className="pb-3"> BTC/USDT</h2>
                                            <div>Previous: {formatNumberToCurrency(previousLastPrice)}</div>
                                            <div>
                                                Current: {formatNumberToCurrency(latestPrice)}&nbsp;
                                                {(previousLastPrice < latestPrice) ?
                                                    <FontAwesomeIcon icon={faArrowUp} className="text-green" />
                                                    : (previousLastPrice > latestPrice) ?
                                                        <FontAwesomeIcon icon={faArrowDown} className="text-red" />
                                                        :
                                                        <></>
                                                }

                                            </div>
                                        </div>

                                        <h1 className="display-3 display-lg-1 pop-outin py-3">WINNER!</h1>
                                        {(previousLastPrice < latestPrice) ?
                                            <h2 className="text-green">Hi</h2>
                                            : (previousLastPrice > latestPrice) ?
                                                <h2 className="text-red">Lo</h2>
                                                :
                                                <h2 className="text-">Draw</h2>
                                        }
                                    </div>
                            }
                        </div>
                    </>

                ) : (
                    <div className="c-loading-data"><div></div>
                        <div></div>
                        <div></div></div>
                )}
            </div>
        </>
    );
};

export default Crypto;