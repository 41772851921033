import React, { useMemo } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Trends from '../partials/members/Trends';
import Bet from '../partials/members/Bet';
import ChoiceHistory from '../partials/members/ChoiceHistory';
import Crypto from '../partials/members/Crypto';

import usePlayLogic from 'components/members/PlayLogic';
import Loader from 'components/Loader';

const Play: React.FC = () => {
    const {
        pusherChannel, pusherChannel2,
        matchId, fightNumber, betStatus, matchResult,
        team1, team2, stream, settleStatus, pusherEvent,
        ongoingBet,
        postTrends,
        handleBetSubmit, loadChoices,
        handleUpdateMatchDetails,
        loadingStatus, loadingStatusTrends,
        getOngoingBets
    } = usePlayLogic();
    
    const currentCredits = localStorage.getItem('total-credits');
    
    const renderStream = useMemo(() => {

        getOngoingBets();

        if (Number(currentCredits) < 100 && ongoingBet === '0') {
            return <div className='m-auto w-100 text-center h5 align-items-center'>A balance of 100 credits is required to view stream</div>;
        }
        if (stream) {
            return (
                <div>
                    {('2' === sessionStorage.getItem('current-category')) ? 
                        <Crypto pusherChannel2={pusherChannel2}/>
                            : stream.includes('iframe') ? (
                        <div className="iframeWrapper" dangerouslySetInnerHTML={{ __html: stream }} />
                    ) : (
                        <div className="iframeWrapper">
                            <iframe width="100%" src={stream} />
                        </div>
                    )}
                </div>
            );
        }
        return <div className='m-auto w-100 text-center h5 align-items-center'>No Stream Available. Please check again later.</div>;
    }, [currentCredits, stream, ongoingBet]);

    return (
        <>
            {loadingStatus ? (
                <Loader />
            ) : (        
                <Container className='play-layout'>
                    <Row>
                        <Col lg={5} md={12}>

                            {
                                
                                    renderStream
                            }
                        </Col>
                        <Col lg={7} md={12} style={{ minHeight: '400px' }}>
                            <Bet
                                fightNumber={fightNumber}
                                matchResult={matchResult}
                                betStatus={betStatus}
                                settleStatus={settleStatus}
                                team1={team1}
                                team2={team2}
                                pusherChannel={pusherChannel}
                                matchIdCurrent={matchId}
                                pusherEvent={pusherEvent}
                                handleBetSubmit={handleBetSubmit}
                                handleUpdateMatchDetails={handleUpdateMatchDetails}
                            />
                            
                            <Trends
                                loadingStatusTrends={loadingStatusTrends}
                                postTrends={postTrends}
                            />
                            <ChoiceHistory
                                handleBetSubmit={handleBetSubmit}
                                loadChoices={loadChoices}
                                settleStatus={settleStatus}
                            />
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default Play;